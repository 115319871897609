.card{
    border:2px solid red;
    padding:20px;
    margin-top: 10px;
    border-radius: 10px;
}

.card__link{
    color:black;
    text-decoration: none;
    font-weight: 600;
    
}

.card__link-internal{
    color:red;
    text-decoration: none;
    font-weight: 600;
    
}

.card > a{
    color:black;
    text-decoration: none;
    font-weight: 600;
}

.card__paragraph{
    padding-top: 10px;
}

.card > p{
    padding-top: 10px;
}